<template>
  <div class="admin-create">
    <div class="admin-navbar align-left">
      <button class="action-button" @click="$router.go(-1)">{{ $lang.app.back }}</button>
    </div>
    <h4 class="create-title">{{ $lang.app.creation }}</h4>
    <div class="admin-form-wrapper">
      <div class="admin-form">
        <Form @submit="submit">
          <div v-if="$store.getters.isPreschool"
               class="reg-step-form-item">
            <label class="recovery-label">{{ $lang.app.title }} <i>*</i></label>
            <div class="reg-step-1-select-container">
              <Field name="name" type="text" v-model="createForm.name"/>
            </div>
            <ErrorMessage name="name" class="recovery-label-error" />
          </div>
          <div v-if="$store.getters.isPreschool"
               class="reg-step-form-item">
            <label class="recovery-label">{{ $lang.app.category }} <i>*</i></label>
            <div class="reg-step-1-select-container">
              <i class="bi-chevron-down"></i>
              <Field as="select" name="preschoolGroupTypeId"
                     v-model="createForm.preschoolGroupTypeId">
                <option v-for="preschoolGroupType in helper.preschoolGroupTypes" :key="preschoolGroupType.id"
                        :value="preschoolGroupType.id">{{ $lang.$translate({ru: preschoolGroupType.nameRu, kg: preschoolGroupType.nameKg}) }}</option>
              </Field>
            </div>
            <ErrorMessage name="preschoolGroupTypeId" class="recovery-label-error" />
          </div>
          <div class="reg-step-form-item">
            <label class="recovery-label">{{ $lang.app.education_language }} <i>*</i></label>
            <div class="reg-step-1-select-container">
              <i class="bi-chevron-down"></i>
              <Field as="select" name="langId" v-model="createForm.langId">
                <option v-for="language in helper.languages" :key="language.id"
                        :value="language.id">{{ language.title }}</option>
              </Field>
            </div>
            <ErrorMessage name="langId" class="recovery-label-error" />
          </div>
          <div class="reg-step-form-item">
            <label class="recovery-label">{{ $lang.app.position_quantity }} <i>*</i></label>
            <div class="reg-step-1-select-container">
              <Field name="quota" type="number" v-model="createForm.quota"/>
            </div>
            <ErrorMessage name="quota" class="recovery-label-error" />
          </div>
          <div class="reg-step-btn">
            <button type="submit">{{ $lang.app.create }}</button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>
<script>
import { Field, Form, ErrorMessage } from 'vee-validate';
export default {
  components: {
    Field,
    Form,
    ErrorMessage,
  },
  data() {
    return {
      helper: {
        preschoolGroupTypes: [],
        languages: []
      },
      createForm: {
        name: '',
        organizationId: this.$store.getters.getOrganizationId ?? this.$route.query.organizationId,
        preschoolGroupTypeId: 0,
        langId: null,
        quota: '',
      }
    }
  },
  created() {
    this.setPreschoolGroupTypes()
    this.setLanguages()
  },
  methods: {
    setPreschoolGroupTypes() {
      if(this.$store.getters.isPreschoolDirector || this.$store.getters.isPreschoolEmployee) {
        this.$axios.get(`/preschool-group-type/list`).then(({data}) => {
          this.helper.preschoolGroupTypes = data
        })
      }
    },
    setLanguages() {
      this.$axios.get(`/lang/${this.$store.getters.getOrganizationId ?? this.$route.query.organizationId}/organization`)
        .then(({data}) => {
          this.helper.languages = data
        })
    },
    submit(values, actions) {
      this.$axios.post('/organization-group/create', this.createForm).then(() => {
        this.$snotify.success(this.$lang.app.resource_created_successfully);
        this.$router.go(-1)
      }).catch(error => {
        if(error.response.status === 400) {
          this.$snotify.error(error.response.data.message);
          actions.setErrors(error.response.data.errors);
        }
      })
    },
  },
}
</script>
<style>
@import '../../../assets/styles/admin/create.css';
@import '../../../assets/styles/admin/navbar.css';
@import '../../../assets/styles/admin/form.css';
@import '../../../assets/styles/form.css';
</style>